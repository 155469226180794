//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller
{
  static targets = [ 'focus' ];
  static values = { modal: Boolean, autoHide: Boolean, autoFocus: Boolean }

  initialize()
  {
    this.showEvent = this.showEvent.bind( this );
    this.submitEvent = this.submitEvent.bind( this );
    this.hiddenEvent = this.hiddenEvent.bind( this );
  }

  connect()
  {
    this.element.addEventListener( 'shown.bs.modal', this.showEvent );
    this.element.addEventListener( 'turbo:submit-end', this.submitEvent );
    this.element.addEventListener( 'hidden.bs.modal', this.hiddenEvent );

    this.modal = new bootstrap.Modal( this.element, this.options );
    this.modal.show();
  }

  disconnect()
  {
    this.element.removeEventListener( 'shown.bs.modal', this.showEvent );
    this.element.removeEventListener( 'turbo:submit-end', this.submitEvent );
    this.element.removeEventListener( 'hidden.bs.modal', this.hiddenEvent );

    if( this.modal )
    {
      this.modal.dispose();
    }
  }

  showEvent( e )
  {
    this.focusElement.focus();
  }

  submitEvent( e )
  {
    if( e.detail.success && this.autoHide )
    {
      this.modal.hide();
    }
  }

  hiddenEvent( e )
  {
    this.element.closest( 'turbo-frame' ).removeAttribute( 'src' );
    this.element.remove();
  }

  get focusElement()
  {
    if( this.hasFocusTarget ) return this.focusTarget;

    if( this.autoFocus )
    {
      let elt;
      if( elt = this.element.querySelector( 'input[autofocus]' )) return elt;
      if( elt = this.element.querySelector( 'input.form-control, textarea.form-control' )) return elt;
      if( elt = this.element.querySelector( 'input[type=submit], button[type=submit]'   )) return elt;
    }

    return this.element;
  }

  get autoFocus()
  {
    return this.hasAutoFocusValue ? this.autoFocusValue : true;
  }

  get autoHide()
  {
    return this.hasAutoHideValue ? this.autoHideValue : true;
  }

  get options()
  {
    return {
      backdrop: this.modalValue ? 'static' : true
    };
  }
}