//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js';

export default class extends Controller
{
  static targets = [ 'passwordInput', 'confirmationInput', 'submitButton', 'scoreBar' ];
  static values = { apiUrl: String };
  static classes = [ 'score0', 'score1', 'score2', 'score3', 'score4', 'checking', 'valid', 'invalid' ];

  initialize()
  {
    this._requestId = 0;
    this._passwordValid = -1;
    this._confirmationValid = -1;
  }

  connect()
  {
    this.disableSubmit();
  }

  async checkPassword()
  {
    const password = this.passwordInputTarget.value;

    if( password.length < 3 )
    {
      this.checking = false;
      this.score = -1;
      this.rating = "";
      this.passwordValid = -1;
    }
    else
    {
      const data = { benutzer: { password: password }};
      const requestId = this.nextRequestId;

      this.checking = true;

      const response = await post( this.apiUrl, { body: JSON.stringify( data ) } );

      if( response.ok )
      {
        if( requestId == this.currentRequestId )
        {
          const result = await response.json
          this.score = result.score;
          this.rating = result.rating;
          this.checking = false;
          this.passwordValid = result.valid ? 1 : 0;
        }
      }
      else
      {
        this.score = -1;
        this.rating = "";
        this.checking = false;
        this.passwordValid = -1;
      }
    }

    this.checkConfirmation();
  }

  checkConfirmation()
  {
    const password = this.passwordInputTarget.value;
    const confirmation = this.confirmationInputTarget.value;

    if( confirmation == "" )
    {
      this.confirmationValid = -1;
    }
    else
    {
      this.confirmationValid = ( confirmation == password ) ? 1 : 0;
    }

    if( this.passwordValid && this.confirmationValid )
    {
      this.enableSubmit();
    }
    else
    {
      this.disableSubmit();
    }
  }

  set checking( checking )
  {
    if( checking )
    {
      this.scoreBarTarget.classList.add( ...this.checkingClass.split( ' ' ) );
      this.scoreBarTarget.style.minWidth = '5%';
    }
    else
    {
      this.scoreBarTarget.classList.remove( ...this.checkingClass.split( ' ' ) );
      this.scoreBarTarget.style.minWidth = '0';
    }
  }

  set score( score )
  {
    const scoreClass = [ this.score0Class, this.score1Class, this.score2Class, this.score3Class, this.score4Class ];

    if( score < 0 )
    {
      this.scoreBarTarget.style.width = '0';
      this.scoreBarTarget.style.minWidth = '0';
      this.scoreBarTarget.classList.remove( ...scoreClass );
    }
    else
    {
      const scorePercentage = ( score + 1 ) * 20
      this.scoreBarTarget.style.width = `${scorePercentage}%`;
      this.scoreBarTarget.classList.remove( ...scoreClass );
      this.scoreBarTarget.classList.add( scoreClass[ score ] );
    }
  }

  set rating( rating )
  {
    this.scoreBarTarget.innerHTML = rating;
  }

  set passwordValid( passwordValid )
  {
    this._passwordValid = passwordValid;

    if( passwordValid < 0 )
    {
      this.passwordInputTarget.classList.remove( ...this.invalidClass.split( ' ' ));
      this.passwordInputTarget.classList.remove( ...this.validClass.split( ' ' ));
    }
    else if( passwordValid > 0 )
    {
      this.passwordInputTarget.classList.remove( ...this.invalidClass.split( ' ' ));
      this.passwordInputTarget.classList.add( ...this.validClass.split( ' ' ));
    }
    else
    {
      this.passwordInputTarget.classList.remove( ...this.validClass.split( ' ' ));
      this.passwordInputTarget.classList.add( ...this.invalidClass.split( ' ' ) );
    }
  }

  get passwordValid()
  {
    return ( this._passwordValid > 0 );
  }


  set confirmationValid( confirmationValid )
  {
    this._confirmationValid = confirmationValid;

    if( confirmationValid < 0 )
    {
      this.confirmationInputTarget.classList.remove( ...this.invalidClass.split( ' ' ));
      this.confirmationInputTarget.classList.remove( ...this.validClass.split( ' ' ));
    }
    else if( confirmationValid > 0 )
    {
      this.confirmationInputTarget.classList.remove( ...this.invalidClass.split( ' ' ));
      this.confirmationInputTarget.classList.add( ...this.validClass.split( ' ' ));
    }
    else
    {
      this.confirmationInputTarget.classList.remove( ...this.validClass.split( ' ' ));
      this.confirmationInputTarget.classList.add( ...this.invalidClass.split( ' ' ));
    }
  }

  get confirmationValid()
  {
    return ( this._confirmationValid > 0 );
  }


  disableSubmit()
  {
    this.submitButtonTarget.setAttribute( 'disabled', true );
  }

  enableSubmit()
  {
    this.submitButtonTarget.removeAttribute( 'disabled' );
  }


  get currentRequestId()
  {
    return this._requestId;
  }

  get nextRequestId()
  {
    return this._requestId += 1;
  }


  get apiUrl()
  {
    if( this.hasApiUrlValue )
    {
      return this.apiUrlValue;
    }
    else
    {
      this.passwordInputTarget.form.action;
    }
  }
}